<template>
  <div class="about">
    <h1>{{ this.$store.state.project.name }}</h1>
    <h2 class="text--secondary">{{ this.$store.state.project.longname }}</h2>
    <div>
      {{ this.$store.state.project.description }}
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>

</style>